import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
// import { Box } from 'grommet';

import './group.styl';

import Container from '../views/components/Container';
import SEO from '../views/components/SEO';
import Hero from '../views/layouts/Hero';
import MainLayout from '../views/layouts/MainLayout';

import ofg from '../images/app/OFG.svg';
import eco from '../images/app/PEFC-FR.svg';
import eco2 from '../images/app/FSC-FR.svg';

const GroupPage = ({
    data,
    location,
    pageContext: { locale, translations },
}) => (
    <MainLayout
        locale={locale}
        translationsPaths={translations}
        neverTransparent
    >
        <SEO
            title='pages.group.headerTitle'
            description='pages.group.description'
            location={location}
            translationsPaths={translations}
        />
        <Hero
            image={data.hero.childImageSharp.gatsbyImageData}
            colors={data.hero.fields.colors}
            title='pages.group.hero.h1'
            text1='pages.group.hero.p1'
            text2='pages.group.hero.p2'
        />
        <main id='content' className='page-group'>
            <section className='group-intro'>
                <Container>
                    <div className='intro'>
                        <h2>Les Éditions du désastre</h2>
                        <FormattedMessage tagName='p' id='pages.group.intro1' />
                    </div>
                    <div className='parts'>
                        <div className='part'>
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.intro2'
                            />
                        </div>
                        <div className='part'>
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.intro3'
                            />
                        </div>
                    </div>
                </Container>
            </section>
            <section className='knowhow dark'>
                <Container>
                    {/* <div className="container"> */}
                    <div className='intro'>
                        <FormattedMessage
                            tagName='h2'
                            id='pages.group.knowHow.title'
                        />
                        <FormattedMessage
                            tagName='p'
                            id='pages.group.knowHow.description'
                        />
                    </div>
                    <div className='box-bloc parts'>
                        <div className='part'>
                            <FormattedMessage
                                tagName='h3'
                                id='pages.group.knowHow.part1.title'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.knowHow.part1.bloc1'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.knowHow.part1.bloc2'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.knowHow.part1.bloc3'
                            />
                        </div>
                        <div className='part'>
                            <FormattedMessage
                                tagName='h3'
                                id='pages.group.knowHow.part2.title'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.knowHow.part2.bloc1'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.knowHow.part2.bloc2'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.knowHow.part2.bloc3'
                            />
                        </div>
                        <div className='part'>
                            <FormattedMessage
                                tagName='h3'
                                id='pages.group.knowHow.part3.title'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.knowHow.part3.bloc1'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.knowHow.part3.bloc2'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.knowHow.part3.bloc3'
                            />
                        </div>
                        <div className='part'>
                            <FormattedMessage
                                tagName='h3'
                                id='pages.group.knowHow.part4.title'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.knowHow.part4.bloc1'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.group.knowHow.part4.bloc2'
                            />
                        </div>
                    </div>
                </Container>
            </section>
            <section className='values'>
                <Container>
                    <div className='container'>
                        <div className='intro'>
                            <FormattedMessage
                                tagName='h2'
                                id='pages.group.value.title'
                            />
                            <p>
                                <FormattedMessage id='pages.group.value.description' />
                            </p>
                        </div>
                        <div className='parts cards-bloc'>
                            <div className='part card'>
                                <div
                                    className='bloc-icon'
                                    style={{ width: 'auto' }}
                                >
                                    <div
                                        className='icon'
                                        style={{
                                            backgroundImage: `url(${ofg})`,
                                            // backgroundColor: "#0163a2"
                                        }}
                                    />
                                </div>

                                <FormattedMessage
                                    id='pages.group.value.part1.title'
                                    tagName='h3'
                                />

                                <FormattedMessage
                                    tagName='p'
                                    id='pages.group.value.part1.description'
                                />
                            </div>
                            <div className='part card'>
                                <div className='bloc-icon'>
                                    <div
                                        className='icon'
                                        style={{
                                            backgroundImage: `url(${eco})`,
                                            width: '45%',
                                            // backgroundColor: "#0163a2"
                                        }}
                                    />
                                    <div
                                        className='icon'
                                        style={{
                                            backgroundImage: `url(${eco2})`,
                                            width: '45%',
                                        }}
                                    >
                                        <FormattedMessage
                                            tagName='span'
                                            id='pages.group.value.part2.note'
                                        />
                                    </div>
                                </div>
                                <FormattedMessage
                                    id='pages.group.value.part2.title'
                                    tagName='h3'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.group.value.part2.description'
                                    values={{ sup: <sup>®</sup> }}
                                />
                            </div>
                            {/* <div className='part card'>
                                    <div className='bloc-icon'>
                                        <div
                                            className='icon'
                                            style={{
                                                backgroundImage: `url(${unicef})`,
                                                // backgroundColor: "#00AEEF"
                                            }}
                                        />
                                    </div>
                                    <FormattedMessage
                                        id='pages.group.value.part3.title'
                                        tagName='h3'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.group.value.part3.description'
                                    />
                                </div> */}
                        </div>
                    </div>
                </Container>
            </section>

            <section className='story'>
                <Container>
                    <div className='container'>
                        <div className='intro'>
                            <FormattedMessage
                                tagName='h2'
                                id='pages.group.history.title'
                            />
                            <p>
                                <FormattedMessage id='pages.group.history.description1' />
                                <br />
                                <FormattedMessage id='pages.group.history.description2' />
                            </p>
                        </div>
                        <div className='box-bloc parts'>
                            <div className='part'>
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.group.history.text1'
                                />
                            </div>
                            <div className='part'>
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.group.history.text2'
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </main>
    </MainLayout>
);
GroupPage.propTypes = {
    data: PropTypes.shape({
        hero: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        translations: PropTypes.array.isRequired,
    }).isRequired,
};

export default GroupPage;

export const pageQuery = graphql`
    query {
        hero: file(
            sourceInstanceName: { eq: "hero" }
            relativePath: { eq: "group.jpg" }
        ) {
            ...BgImages
        }
    }
`;
