import clsx from 'clsx';
// import BackgroundImage from 'gatsby-background-image-es5';
import { Box, ResponsiveContext } from 'grommet';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import './Hero.styl';
import Container from '../../components/Container';
import Image from '../../components/Image';

const Hero = ({
    className,
    colors,
    image,
    text1,
    text2,
    textColorDark,
    title,
    titleColorDark,
    titleNoTranslation,
}) => {
    const size = useContext(ResponsiveContext);
    const isSmall = size === 'small';
    const titleColor = titleColorDark && !isSmall ? '#4a4a4a' : '#fff';
    const textColor = textColorDark && !isSmall ? '#4a4a4a' : '#fff';
    // const style = isSmall
    //     ? {
    //           //   maxHeight: '100%',
    //           backgroundSize: 'contain',
    //           height: `calc(100vw / ${image.aspectRatio})`,
    //       }
    //     : {
    //           backgroundSize: 'cover',
    //       };

    const getContent = () => (
        <Container
            // pad={{ horizontal: isSmall ? 'medium' : 'xlarge', width: '100%' }}
            fill='vertical'
            align={isSmall ? 'center' : 'end'}
            // responsive
        >
            <div
                className='bloc1'
                style={{
                    color: titleColor,
                    top: text1 !== '' ? '35%' : '50%',
                }}
            >
                {titleNoTranslation ? null : (
                    <FormattedMessage id={title} tagName='h1' />
                )}

                {text1 && (
                    <div className='bloc2' style={{ color: textColor }}>
                        <FormattedMessage
                            id={text1}
                            tagName='p'
                            values={{
                                breakingLine: <br />,
                            }}
                        />
                        {text2 && (
                            <div className='bloc3'>
                                <FormattedMessage
                                    id={text2}
                                    tagName='p'
                                    values={{
                                        breakingLine: <br />,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Container>
    );
    return (
        <Box
            style={{
                // backgroundColor: '#070636',
                backgroundColor: colors.darkMuted,
                height: isSmall
                    ? 'auto'
                    : // ? `calc(100vw / ${image.aspectRatio} + 260px)`
                      '75vh',

                maxHeight: '100%',
            }}
        >
            {isSmall ? (
                <div className='hero-section small'>
                    <Image
                        image={image}
                        layout='fullWidth'
                        objectFit='contain'
                        objectPosition='50% 50%'
                        style={{
                            height: 'auto',
                            minWidth: '100vw',
                            overflow: 'initial',
                            width: '100vw',
                            // height: '100%',
                            // height: `calc(100vw / ${image.aspectRatio})`,
                        }}
                        alt={title}
                    />
                    {titleNoTranslation ? null : getContent()}
                </div>
            ) : (
                <div
                    className={clsx('hero-section', className)}
                    style={{ height: '100%', width: '100%' }}
                >
                    <Image
                        layout='fullWidth'
                        image={image}
                        alt={title}
                        style={{
                            height: '75vh',
                            minWidth: '100vw',
                            overflow: 'initial',
                            position: 'absolute',
                            width: '100vw',
                            // zIndex: -1,
                        }}
                    />
                    {getContent()}
                </div>
            )}
        </Box>
    );
};

Hero.defaultProps = {
    className: '',
    colors: { darkMuted: 'transparent' },
    text1: '',
    text2: '',
    textColorDark: false,
    titleColorDark: false,
    titleNoTranslation: false,
};

Hero.propTypes = {
    className: PropTypes.string,
    colors: PropTypes.object,
    image: PropTypes.object.isRequired,
    text1: PropTypes.string,
    text2: PropTypes.string,
    textColorDark: PropTypes.bool,
    title: PropTypes.string.isRequired,
    titleColorDark: PropTypes.bool,
    titleNoTranslation: PropTypes.bool,
};

export default Hero;
